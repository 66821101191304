import React, { useCallback, useLayoutEffect } from 'react'
import { isExactMatch } from "../../utlis/dataUtils";
import productDescriptions from "../../data/productDescriptions.json";

import { CUSTOM_PRODUCTS } from '../../constants/products';
import CustomProduct from './CustomProduct';

const ShopifyProductCollection = ({ clientGender, zenagenUi, hairmaxUi, hairThinningLevel, clinicId }) => {
  const handleChangeTogglePosition = useCallback(() => {
    setTimeout(() => {
      const toggleButtons = document.querySelectorAll(".shopify-buy-frame.shopify-buy-frame--toggle.is-sticky.is-active")
      toggleButtons?.forEach((button, index) => {
        const length = toggleButtons.length;
        const height = button.offsetHeight;
        const windowHeight = window.innerHeight;
        const top = (windowHeight / 2) - (height * length / 2) + (height * (index * 1.1));
        button.style.top = `${top}px`;
      })
    }, 1000)
  }, [])

  useLayoutEffect(() => {
    zenagenUi?.createComponent('collection', {
      id: '421304729840',
      node: document.getElementById('collection-component-1696521037415'),
      moneyFormat: '%24%7B%7Bamount%7D%7D',
      options: {
        product: {
          order: [
            'img',
            'imgWithCarousel',
            'title',
            'variantTitle',
            'price',
            'quantity',
            'options',
            'button',
            'buttonWithQuantity',
            'description',
          ],
          contents: {
            img: true,
            imgWithCarousel: false,
            title: true,
            variantTitle: false,
            price: true,
            unitPrice: true,
            options: true,
            quantity: false,
            quantityIncrement: false,
            quantityDecrement: false,
            quantityInput: true,
            button: true,
            buttonWithQuantity: false,
            description: true,
          },
          templates: {
            price: `<div class="{{data.classes.product.prices}}" data-element="product.prices">
          {{#data.selectedVariant}}
          <span class="visuallyhidden">{{data.priceAccessibilityLabel}}&nbsp;</span>
          <span class="{{data.classes.product.variantTitle}}" data-element="product.variantTitle">{{data.selectedVariant.title}}</span>
          <span class="{{data.classes.product.price}} {{data.priceClass}}" data-element="product.price">{{data.formattedPrice}}</span>
          {{#data.hasCompareAtPrice}}
          <span class="visuallyhidden">{{data.compareAtPriceAccessibilityLabel}}&nbsp;</span>
          <span class="{{data.classes.product.compareAt}}" data-element="product.compareAt">{{data.formattedCompareAtPrice}}</span>
          {{/data.hasCompareAtPrice}}
          {{#data.showUnitPrice}}
          <div class="{{data.classes.product.unitPrice}}" data-element="product.unitPrice">
            <span class="visuallyhidden">{{data.text.unitPriceAccessibilityLabel}}</span>
            {{data.formattedUnitPrice}}<span aria-hidden="true">/</span><span class="visuallyhidden">&nbsp;{{data.text.unitPriceAccessibilitySeparator}}&nbsp;</span>{{data.formattedUnitPriceBaseUnit}}
          </div>
          {{/data.showUnitPrice}}
          {{/data.selectedVariant}}
        </div>`,
            button: `<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
                  <button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">
                    {{data.buttonText}}
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="white" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                  </button>
                  <div class="{{data.classes.product.infoToggleWrapper}}">
                    More Info
                    <svg class="{{data.classes.product.infoToggler}} {{data.classes.product.flipIcon}}" fill="#ffffff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-300.3 -300.3 930.60 930.60" xml:space="preserve" stroke="#ffffff" stroke-width="33">
                      <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                      <rect x="-300.3" y="-300.3" width="930.60" height="930.60" rx="465.3" fill="#f4831f" strokewidth="0"/>
                      </g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.64"/>
                      <g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/> </g>
                    </svg>
                  </div>
                </div>`,
            description: '<div class="{{data.classes.product.description}} {{data.classes.product.hidden}}" data-element="product.description">{{{data.descriptionHtml}}}</div>'
          },
          classes: {
            infoToggleWrapper: "info-toggle-wrapper",
            infoToggler: "info-toggler",
            flipIcon: "flipped-icon",
            hidden: "hidden"
          },
          styles: {
            product: {
              "@media (min-width: 100px)": {
                "margin": "0px",
                "margin-bottom": "50px",
                "width": "100%"
              },
              "img": {
                "height": "75%",
                "position": "absolute",
                "left": "50%",
                "top": "50%",
                "transform": "translate(-50%, -50%)",
              },
              "imgWrapper": {
                "padding-top": "100%",
                "position": "relative",
                "height": "0",
                "box-shadow": "0px 0px 45px 1px #c1c1c1 inset",
                "margin-bottom": "15px"
              },
              "title": {
                "font-size": "25px",
                "font-weight": "bold"
              },
              "price": {
                "font-weight": "700",
                "font-style": "italic",
                "font-size": "18px"
              },
              "buttonWrapper": {
                "margin": "25px auto 0px auto",
                "display": "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "gap": "20px"
              },
              "button": {
                "background-color": "#f4831f",
                "color": "white",
                "padding": "8px 22px",
                "font-size": "18px",
                "display": "flex",
                "align-items": "center",
                "gap": "10px",
                "border-radius": "8px",
                "margin": "0px",
                ":hover": {
                  "background-color": "#f4831f"
                }
              },
              "description": {
                "font-size": "18px",
                "background-color": "#F4831F",
                "line-height": "22px",
                "padding": "16px 24px",
                "font-weight": "normal",
                "color": "#efefef",
              },
              "variantTitle": {
                "font-size": "17px",
                "font-weight": "bold"
              },
              "infoToggleWrapper": {
                "color": "black",
                "font-size": "18px",
                "display": "flex",
                "align-items": "center",
                "gap": "10px",
              },
              "infoToggler": {
                "cursor": "pointer",
              },
              "flipIcon": {
                "transform": " rotate(180deg)"
              },
              "hidden": {
                "display": "none"
              }

            }
          },
          text: {
            "button": "Add to cart"
          },
          DOMEvents: {
            'click .info-toggler': function (e, target) {
              // toggle description visibility
              const productDescription = target?.parentNode?.parentNode?.nextSibling;
              target?.classList?.toggle("flipped-icon");
              productDescription?.classList?.toggle("hidden");

              // // update iframe height
              const iframe = document?.querySelector('[name="frame-productSet-421304729840"]');
              const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;
              const height = innerDoc?.querySelector(".shopify-buy__collection")?.offsetHeight;
              if (iframe) iframe.style.height = `${height}px`
            },
          },
          events: {
            addVariantToCart: handleChangeTogglePosition,
          }
        },

        productSet: {
          "styles": {
            "products": {
              "@media (min-width: 101px)": {
                // "width": "100%"
                margin: "0px"

              }
            }
          }
        },
        modalProduct: {
          "contents": {
            "img": false,
            "imgWithCarousel": true,
            "button": false,
            "buttonWithQuantity": true
          },
          "styles": {
            "product": {
              "@media (min-width: 101px)": {
                "max-width": "100%",
                "width": "100%",
                "margin-bottom": "0px"
              }
            }
          },
          "text": {
            "button": "Add to cart"
          }
        },
        option: {},
        cart: {
          "text": {
            "total": "Subtotal",
            "button": "Checkout"
          }
        },
      },
    }).then(() => {
      handleChangeTogglePosition()


      const iframe = document?.querySelector('[name="frame-productSet-421304729840"]');
      const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;

      const productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

      productTitles?.forEach(title => {
        const isMaleProduct = isExactMatch(" men", title?.innerText?.toLowerCase());
        const isFemaleProduct = isExactMatch("women", title?.innerText?.toLowerCase());

        const product = title?.closest(".shopify-buy__product");

        if (clientGender === "men" && isFemaleProduct && product) product.style.display = "none";
        if (clientGender === "women" && isMaleProduct && product) product.style.display = "none";

        const productDescription = product?.querySelector('[data-element="product.description"]');
        if (productDescription && productDescriptions[title?.innerText]) productDescription.innerHTML = productDescriptions[title?.innerText];
      })


      const height = innerDoc?.querySelector(".shopify-buy__collection")?.offsetHeight;
      if (iframe) iframe.style.height = `${height}px`;
    });
  }, [clientGender, zenagenUi, handleChangeTogglePosition])

  useLayoutEffect(() => {
    zenagenUi?.createComponent('collection', {
      id: '424326660336',
      node: document.getElementById('collection-component-1701800607247'),
      moneyFormat: '%24%7B%7Bamount%7D%7D',
      options: {
        product: {
          order: [
            'img',
            'imgWithCarousel',
            'title',
            'variantTitle',
            'price',
            'quantity',
            'options',
            'button',
            'buttonWithQuantity',
            'description',
          ],
          contents: {
            img: true,
            imgWithCarousel: false,
            title: true,
            variantTitle: false,
            price: true,
            unitPrice: true,
            options: true,
            quantity: false,
            quantityIncrement: false,
            quantityDecrement: false,
            quantityInput: true,
            button: true,
            buttonWithQuantity: false,
            description: true,
          },
          templates: {
            price: `<div class="{{data.classes.product.prices}}" data-element="product.prices">
                  {{#data.selectedVariant}}
                  <span class="visuallyhidden">{{data.priceAccessibilityLabel}}&nbsp;</span>
                  <span class="{{data.classes.product.variantTitle}}" data-element="product.variantTitle">{{data.selectedVariant.title}}</span>
                  <span class="{{data.classes.product.price}} {{data.priceClass}}" data-element="product.price">{{data.formattedPrice}}</span>
                  {{#data.hasCompareAtPrice}}
                  <span class="visuallyhidden">{{data.compareAtPriceAccessibilityLabel}}&nbsp;</span>
                  <span class="{{data.classes.product.compareAt}}" data-element="product.compareAt">{{data.formattedCompareAtPrice}}</span>
                  {{/data.hasCompareAtPrice}}
                  {{#data.showUnitPrice}}
                  <div class="{{data.classes.product.unitPrice}}" data-element="product.unitPrice">
                    <span class="visuallyhidden">{{data.text.unitPriceAccessibilityLabel}}</span>
                    {{data.formattedUnitPrice}}<span aria-hidden="true">/</span><span class="visuallyhidden">&nbsp;{{data.text.unitPriceAccessibilitySeparator}}&nbsp;</span>{{data.formattedUnitPriceBaseUnit}}
                  </div>
                  {{/data.showUnitPrice}}
                  {{/data.selectedVariant}}
                </div>`,
            button: `<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
                          <button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">
                            {{data.buttonText}}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="white" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                          </button>
                          <div class="{{data.classes.product.infoToggleWrapper}}">
                            More Info
                            <svg class="{{data.classes.product.infoToggler}} {{data.classes.product.flipIcon}}" fill="#ffffff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-300.3 -300.3 930.60 930.60" xml:space="preserve" stroke="#ffffff" stroke-width="33">
                              <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                              <rect x="-300.3" y="-300.3" width="930.60" height="930.60" rx="465.3" fill="#f4831f" strokewidth="0"/>
                              </g>
                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.64"/>
                              <g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/> </g>
                            </svg>
                          </div>
                        </div>`,
            description: '<div class="{{data.classes.product.description}} {{data.classes.product.hidden}}" data-element="product.description">{{{data.descriptionHtml}}}</div>'
          },
          classes: {
            infoToggleWrapper: "info-toggle-wrapper",
            infoToggler: "info-toggler",
            flipIcon: "flipped-icon",
            hidden: "hidden"
          },
          styles: {
            product: {
              "@media (min-width: 100px)": {
                "margin": "0px",
                "margin-bottom": "50px",
                "width": "100%"
              },
              "img": {
                "height": "75%",
                "position": "absolute",
                "left": "50%",
                "top": "50%",
                "transform": "translate(-50%, -50%)",
              },
              "imgWrapper": {
                "padding-top": "100%",
                "position": "relative",
                "height": "0",
                "box-shadow": "0px 0px 45px 1px #c1c1c1 inset",
                "margin-bottom": "15px"
              },
              "title": {
                "font-size": "25px",
                "font-weight": "bold"
              },
              "price": {
                "font-weight": "700",
                "font-style": "italic",
                "font-size": "18px"
              },
              "buttonWrapper": {
                "margin": "25px auto 0px auto",
                "display": "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "gap": "20px"
              },
              "button": {
                "background-color": "#f4831f",
                "color": "white",
                "padding": "8px 22px",
                "font-size": "18px",
                "display": "flex",
                "align-items": "center",
                "gap": "10px",
                "border-radius": "8px",
                "margin": "0px",
                ":hover": {
                  "background-color": "#f4831f"
                }
              },
              "description": {
                "font-size": "18px",
                "background-color": "#F4831F",
                "line-height": "22px",
                "padding": "16px 24px",
                "font-weight": "normal",
                "color": "#efefef",
              },
              "variantTitle": {
                "font-size": "17px",
                "font-weight": "bold"
              },
              "infoToggleWrapper": {
                "color": "black",
                "font-size": "18px",
                "display": "flex",
                "align-items": "center",
                "gap": "10px",
              },
              "infoToggler": {
                "cursor": "pointer",
              },
              "flipIcon": {
                "transform": " rotate(180deg)"
              },
              "hidden": {
                "display": "none"
              }

            }
          },
          text: {
            "button": "Add to cart"
          },
          DOMEvents: {
            'click .info-toggler': function (e, target) {
              // toggle description visibility
              const productDescription = target?.parentNode?.parentNode?.nextSibling;
              target?.classList?.toggle("flipped-icon");
              productDescription?.classList?.toggle("hidden");

              // // update iframe height
              const iframe = document?.querySelector('[name="frame-productSet-424326660336"]');
              const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;
              const height = innerDoc?.querySelector(".shopify-buy__collection")?.offsetHeight;
              if (iframe) iframe.style.height = `${height}px`
            },
          },
          events: {
            addVariantToCart: handleChangeTogglePosition,
          }
        },
        productSet: {
          "styles": {
            "products": {
              "@media (min-width: 101px)": {
                // "width": "100%"
                margin: "0px"

              }
            }
          }
        },
        modalProduct: {
          "contents": {
            "img": false,
            "imgWithCarousel": true,
            "button": false,
            "buttonWithQuantity": true
          },
          "styles": {
            "product": {
              "@media (min-width: 101px)": {
                "max-width": "100%",
                "width": "100%",
                "margin-bottom": "0px"
              }
            }
          },
          "text": {
            "button": "Add to cart"
          }
        },
        option: {},
        cart: {
          "text": {
            "total": "Subtotal",
            "button": "Checkout"
          }
        },
      },
    }).then(() => {
      handleChangeTogglePosition()

      const iframe = document?.querySelector('[name="frame-productSet-424326660336"]');
      const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;

      const productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

      productTitles?.forEach(title => {
        const isMaleProduct = isExactMatch(" men", title?.innerText?.toLowerCase());
        const isFemaleProduct = isExactMatch("women", title?.innerText?.toLowerCase());

        const product = title?.closest(".shopify-buy__product");

        if (clientGender === "men" && isFemaleProduct && product) product.style.display = "none";
        if (clientGender === "women" && isMaleProduct && product) product.style.display = "none";

      })

      const height = innerDoc?.querySelector(".shopify-buy__collection")?.offsetHeight;
      if (iframe) iframe.style.height = `${height}px`
    });
  }, [clientGender, zenagenUi, handleChangeTogglePosition])

  useLayoutEffect(() => {
    if (!hairThinningLevel || hairThinningLevel < 3 || Boolean(document.querySelector('#product-component-1705416678287')?.firstChild)) return;
    hairmaxUi.createComponent('product', {
      id: '4635653111926',
      node: document.getElementById('product-component-1705416678287'),
      moneyFormat: '%7B%7Bamount%7D%7D',
      options: {
        product: {
          order: [
            'img',
            'imgWithCarousel',
            'title',
            'variantTitle',
            'price',
            'quantity',
            'options',
            'button',
            'buttonWithQuantity',
            'description',
          ],
          contents: {
            img: true,
            imgWithCarousel: false,
            title: true,
            variantTitle: false,
            price: true,
            unitPrice: true,
            options: true,
            quantity: false,
            quantityIncrement: false,
            quantityDecrement: false,
            quantityInput: true,
            button: true,
            buttonWithQuantity: false,
            description: true,
          },
          templates: {
            price: `<div class="{{data.classes.product.prices}}" data-element="product.prices">
                  {{#data.selectedVariant}}
                  <span class="visuallyhidden">{{data.priceAccessibilityLabel}}&nbsp;</span>
                  <span class="{{data.classes.product.variantTitle}}" data-element="product.variantTitle">{{data.selectedVariant.title}}</span>
                  <span class="{{data.classes.product.price}} {{data.priceClass}}" data-element="product.price">${`$`}{{data.formattedPrice}}</span>
                  {{#data.hasCompareAtPrice}}
                  <span class="visuallyhidden">{{data.compareAtPriceAccessibilityLabel}}&nbsp;</span>
                  <span class="{{data.classes.product.compareAt}}" data-element="product.compareAt">{{data.formattedCompareAtPrice}}</span>
                  {{/data.hasCompareAtPrice}}
                  {{#data.showUnitPrice}}
                  <div class="{{data.classes.product.unitPrice}}" data-element="product.unitPrice">
                    <span class="visuallyhidden">{{data.text.unitPriceAccessibilityLabel}}</span>
                    {{data.formattedUnitPrice}}<span aria-hidden="true">/</span><span class="visuallyhidden">&nbsp;{{data.text.unitPriceAccessibilitySeparator}}&nbsp;</span>{{data.formattedUnitPriceBaseUnit}}
                  </div>
                  {{/data.showUnitPrice}}
                  {{/data.selectedVariant}}
                </div>`,
            button: `<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
                          <button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">
                            {{data.buttonText}}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="white" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                          </button>
                          <div class="{{data.classes.product.infoToggleWrapper}}">
                            More Info
                            <svg class="{{data.classes.product.infoToggler}} {{data.classes.product.flipIcon}}" fill="#ffffff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-300.3 -300.3 930.60 930.60" xml:space="preserve" stroke="#ffffff" stroke-width="33">
                              <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                              <rect x="-300.3" y="-300.3" width="930.60" height="930.60" rx="465.3" fill="#f4831f" strokewidth="0"/>
                              </g>
                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.64"/>
                              <g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/> </g>
                            </svg>
                          </div>
                        </div>`,
            description: '<div class="{{data.classes.product.description}} {{data.classes.product.hidden}}" data-element="product.description">{{{data.descriptionHtml}}}</div>'
          },
          classes: {
            infoToggleWrapper: "info-toggle-wrapper",
            infoToggler: "info-toggler",
            flipIcon: "flipped-icon",
            hidden: "hidden"
          },
          styles: {
            product: {
              "@media (min-width: 100px)": {
                "margin": "0px",
                "margin-bottom": "50px",
                "width": "100%"
              },
              "img": {
                "height": "75%",
                "position": "absolute",
                "left": "50%",
                "top": "50%",
                "transform": "translate(-50%, -50%)",
              },
              "imgWrapper": {
                "padding-top": "100%",
                "position": "relative",
                "height": "0",
                "box-shadow": "0px 0px 45px 1px #c1c1c1 inset",
                "margin-bottom": "15px"
              },
              "title": {
                "font-size": "25px",
                "font-weight": "bold"
              },
              "price": {
                "font-weight": "700",
                "font-style": "italic",
                "font-size": "18px"
              },
              "buttonWrapper": {
                "margin": "25px auto 0px auto",
                "display": "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "gap": "20px"
              },
              "button": {
                "background-color": "#f4831f",
                "color": "white",
                "padding": "8px 22px",
                "font-size": "18px",
                "display": "flex",
                "align-items": "center",
                "gap": "10px",
                "border-radius": "8px",
                "margin": "0px",
                ":hover": {
                  "background-color": "#f4831f"
                }
              },
              "description": {
                "font-size": "18px",
                "background-color": "#F4831F",
                "line-height": "22px",
                "padding": "16px 24px",
                "font-weight": "normal",
                "color": "#efefef",
              },
              "variantTitle": {
                "font-size": "17px",
                "font-weight": "bold"
              },
              "infoToggleWrapper": {
                "color": "black",
                "font-size": "18px",
                "display": "flex",
                "align-items": "center",
                "gap": "10px",
              },
              "infoToggler": {
                "cursor": "pointer",
              },
              "flipIcon": {
                "transform": " rotate(180deg)"
              },
              "hidden": {
                "display": "none"
              }

            }
          },
          text: {
            "button": "Add to cart"
          },
          DOMEvents: {
            'click .info-toggler': function (e, target) {
              // toggle description visibility
              const productDescription = target?.parentNode?.parentNode?.nextSibling;
              target?.classList?.toggle("flipped-icon");
              productDescription?.classList?.toggle("hidden");

              // // update iframe height
              const iframe = document?.querySelector('[name="frame-product-4635653111926"]');
              const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;
              const height = innerDoc?.querySelector(".shopify-buy__product")?.offsetHeight;
              if (iframe) iframe.style.height = `${height}px`
            },
          },
          events: {
            addVariantToCart: handleChangeTogglePosition,
          }
        },
        productSet: {
          "styles": {
            "products": {
              "@media (min-width: 101px)": {
                // "width": "100%"
                margin: "0px"

              }
            }
          }
        },
        modalProduct: {
          "contents": {
            "img": false,
            "imgWithCarousel": true,
            "button": false,
            "buttonWithQuantity": true
          },
          "styles": {
            "product": {
              "@media (min-width: 101px)": {
                "max-width": "100%",
                "width": "100%",
                "margin-bottom": "0px"
              }
            }
          },
          "text": {
            "button": "Add to cart"
          }
        },
        option: {},
        cart: {
          "text": {
            "total": "Subtotal",
            "button": "Checkout"
          }
        },
      },
    }).then(() => {
      handleChangeTogglePosition()

      const collectionParent = document.getElementById('product-component-1705416678287');
      if (collectionParent) collectionParent.style.maxWidth = "100%";

      const iframe = document?.querySelector('[name="frame-product-4635653111926"]');
      const innerDoc = (iframe?.contentDocument) ? iframe?.contentDocument : iframe?.contentWindow.document;

      const productTitles = [...(innerDoc?.querySelectorAll('[data-element="product.title"]'))];

      productTitles?.forEach(title => {
        const isMaleProduct = isExactMatch(" men", title?.innerText?.toLowerCase());
        const isFemaleProduct = isExactMatch("women", title?.innerText?.toLowerCase());

        const product = title?.closest(".shopify-buy__product");

        if (clientGender === "men" && isFemaleProduct && product) product.style.display = "none";
        if (clientGender === "women" && isMaleProduct && product) product.style.display = "none";

        const productDescription = product?.querySelector('[data-element="product.description"]');
        if (productDescription && productDescriptions[title?.innerText]) productDescription.innerHTML = productDescriptions[title?.innerText];
      })

      const height = innerDoc?.querySelector(".shopify-buy__collection")?.offsetHeight;
      if (iframe) iframe.style.height = `${height}px`;

    });
  }, [clientGender, hairmaxUi, hairThinningLevel, handleChangeTogglePosition]);

  return (
    <div className="mt-20 w-11/12 md:w-4/5 lg:w-3/4 xl:w-3/5">
      <div id='collection-component-1696521037415' className="w-full"></div>
      <div id='collection-component-1701800607247' className="w-full"></div>
      <div id='product-component-1705416678287' className="w-full"></div>
      {CUSTOM_PRODUCTS.map((product, index) => {
        if (product.showForOnlyOneClinic && product.clinicId !== clinicId) return null;

        return (
          <CustomProduct product={product} key={index} />
        )
      })}
    </div>
  )
}

export default ShopifyProductCollection