import CalecimProductImage from '../assets/products/calecim.png';
import CalecimProductImageOneBefore from '../assets/products/calecim-results-1-before.png';
import CalecimProductImageOneAfter from '../assets/products/calecim-results-1-after.png';
import CalecimProductImageTwoBefore from '../assets/products/calecim-results-2-before.png';
import CalecimProductImageTwoAfter from '../assets/products/calecim-results-2-after.png';

// female images
import Image1BeforeFemale from "../assets/products/normal-product-1-before.png"
import Image1AfterFemale from "../assets/products/normal-product-1-after.png"
import Image2BeforeFemale from "../assets/products/normal-product-2-before.png"
import Image2AfterFemale from "../assets/products/normal-product-2-after.png"
import Image3BeforeFemale from "../assets/products/normal-product-3-before.png"
import Image3AfterFemale from "../assets/products/normal-product-3-after.png"


// male images
import Image1BeforeMale from "../assets/products/normal-product-1-before-male.png"
import Image1AfterMale from "../assets/products/normal-product-1-after-male.png"
import Image2BeforeMale from "../assets/products/normal-product-2-before-male.png"
import Image2AfterMale from "../assets/products/normal-product-2-after-male.png"
import Image3BeforeMale from "../assets/products/normal-product-3-before-male.png"
import Image3AfterMale from "../assets/products/normal-product-3-after-male.png"

export const CUSTOM_PRODUCTS = Object.freeze([
    {
        img: CalecimProductImage,
        title: 'Advanced Hair System',
        price: '$360',
        link: 'https://calecimprofessional.com/products/advanced-hair-system?variant=43491694215399',
        description: `6-week hair restoration program
Contains powerful PTT-6® that stimulates hair growth by activating the hair follicles, and managing signs of inflammation that causes hair shedding.
Comes with an activator to prep the scalp for maximum results.`,
        showForOnlyOneClinic: true,
        clinicId: 197,
        images: [
            {
                before: CalecimProductImageOneBefore,
                after: CalecimProductImageOneAfter,
                gender: "M"
            },
            {
                before: CalecimProductImageTwoBefore,
                after: CalecimProductImageTwoAfter,
                gender: "F"
            }
        ]
    }
])

export const GENERIC_PRODUCT_RESULT_IMAGES = {
    FEMALE: [
        {
            before_image: {
                image_path: Image1BeforeFemale,
            },
            after_image: {
                image_path: Image1AfterFemale,
            }
        },
        {
            before_image: {
                image_path: Image2BeforeFemale,
            },
            after_image: {
                image_path: Image2AfterFemale,
            }
        },
        {
            before_image: {
                image_path: Image3BeforeFemale,
            },
            after_image: {
                image_path: Image3AfterFemale,
            }
        }
    ],
    MALE: [
        {
            before_image: {
                image_path: Image1BeforeMale,
            },
            after_image: {
                image_path: Image1AfterMale,
            }
        },
        {
            before_image: {
                image_path: Image2BeforeMale,
            },
            after_image: {
                image_path: Image2AfterMale,
            }
        },
        {
            before_image: {
                image_path: Image3BeforeMale,
            },
            after_image: {
                image_path: Image3AfterMale,
            }
        }
    ]
}