import React, { useEffect, useMemo, useState } from "react";
import GroCamera from "../../assets/images/GroTrackCamera.png";
import LessThanIcon from "../../assets/images/lessThanIcon.svg";
import GreaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import { AiOutlineInfoCircle } from "react-icons/ai"
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "../../contexts/TranslationContext";
import { CUSTOM_PRODUCTS, GENERIC_PRODUCT_RESULT_IMAGES } from "../../constants/products";

const ProductResults = ({ clientGender = "M", clinicId }) => {
  const matches = useMediaQuery('(max-width: 1026px)');
  const { translate } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);

  const results = useMemo(() => {
    try {

      const productResults = clientGender === "M" ?  GENERIC_PRODUCT_RESULT_IMAGES.MALE : GENERIC_PRODUCT_RESULT_IMAGES.FEMALE;

      const relevantCustomProducts = CUSTOM_PRODUCTS.filter((product) => {
        if(product?.showForOnlyOneClinic && product?.clinicId !== clinicId) return false;
        return true;
      })
      
      const customResultImages = relevantCustomProducts.flatMap((product) => {
        const images = product?.images?.filter((img) => {
          if (clientGender === "F" || clientGender === "O") return img?.gender === "F"
          if (clientGender === "M") return img?.gender === "M"
          return false;
        })
        return images.map((imageData) => {
          return {
            before_image: {
              image_path: imageData?.before
            },
            after_image: {
              image_path: imageData?.after
            }
          }
        })
      })
  
      return [
        ...productResults,
        ...customResultImages
      ]
    } catch(error) {
      console.log({error})
      return [];
    }
  }, [clientGender, clinicId])

  const handleChangeIndex = (action) => {
    switch (action) {
      case "next":
        if (currentIndex >= (results?.length - 1)) return;
        setCurrentIndex((prev) => prev + 1);
        return;
      case "prev":
        if (currentIndex === 0) return;
        setCurrentIndex((prev) => prev - 1);
        return;
      default:
        return;
    }
  };



  return (
    <section
      className="min-w-screen bg-white  text-black p-4 px-2 pb-4"
      style={{ flexGrow: 1 }}
    >
      <main className="text-center flex flex-col justify-evenly items-center w-full sm:w-3/4 md:w-1/2 mx-auto">

        <h1 className="text-2xl uppercase tracking-normal mt-4  mb-6">{translate('results')}</h1>

        <ResultsCarousel
          results={results}
          currentIndex={currentIndex}
          handleChangeIndex={handleChangeIndex}
        />
        <h1 className="text-xl uppercase mt-12 my-3 tracking-normal">
          {translate('money_back_guarantee')}
        </h1>

        <div className="flex flex-col px-6">
          <div className="flex pt-1 pb-3 ">
            <img src={GroCamera} className="w-6/12" />
            <div
              className="text-sm text-left font-bold"
              style={{ fontFamily: "Roboto", lineHeight: "1.125rem" }}
            >
              {translate('grotrack_results_refund')}
              <div className="relative group inline ml-1">
                <AiOutlineInfoCircle className="w-5 inline text-button-5" />
                <div className="hidden group-hover:flex absolute top-1/2 right-0 lg:top-8 lg:left-1/2 z-10 flex-col items-center" style={{ transform: matches ? 'translate(-20px, -50%)' : 'translate(-50%, -8px)' }}>
                  <div className="px-3 py-2 text-xs lg:text-sm font-medium text-white bg-gray-500 rounded-lg shadow-sm w-auto" style={{ minWidth: '250px' }}>
                    {!matches && <svg className="absolute text-gray-500 h-2 w-auto left-0 top-0" style={{ transform: "rotate(180deg) translate(0px, 8px)" }} x="0px" y="0px" viewBox="0 0 255 255"><polygon class="fill-current" points="0,0 127.5,127.5 255,0" /></svg>}
                    {translate('six_months_use')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </section>
  );
};

const ResultsCarousel = ({ results = [], currentIndex, handleChangeIndex }) => {
  useEffect(() => {
    // Update carousel on props change
  }, [results, currentIndex]);
  return (
    <div className="w-full grid grid-cols-2 gap-1 border border-gray-400 relative">

      {/* images */}
      {results.map((imageSet, index) => {
        const isActiveItem = index === currentIndex;
        const beforeImageUrl = imageSet?.before_image?.image_path;
        const afterImageUrl = imageSet?.after_image?.image_path;
        return (
          <React.Fragment key={index}>
            <div className={`col-span-1 ${isActiveItem ? '' : 'hidden'} aspect-w-16 aspect-h-16 lg:aspect-h-14`}>
              <img
                src={beforeImageUrl}
                className="w-full h-full object-cover"
              />
            </div>
            <div className={`col-span-1 ${isActiveItem ? '' : 'hidden'} aspect-w-16 aspect-h-14`}>
              <img src={afterImageUrl} className="w-full h-full object-cover" />
            </div>
          </React.Fragment>
        );
      })}

      {/* nav buttons */}
      <div
        className="absolute top-1/2 w-full flex justify-between"
        style={{ transform: "translate(0, -50%)" }}
      >
        <button
          className="bg-transparent p-0 cursor-pointer"
          onClick={() => {
            handleChangeIndex("prev");
          }}
        >
          <img src={LessThanIcon} className="h-10 w-10" />
        </button>
        <button
          className="bg-transparent p-0 cursor-pointer"
          onClick={() => {
            handleChangeIndex("next");
          }}
        >
          <img src={GreaterThanIcon} className="w-10 h-10" />
        </button>
      </div>
    </div>
  );
};

export default ProductResults;
